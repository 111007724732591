import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import isEqual from 'lodash-es/isEqual';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { ToastService } from '@core-services/toast.service';
import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import * as foldersActions from '@folders/store/actions/folders.actions';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';
import { ListingCategories } from '@listings/enums/listing-categories.enum';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { SavedSearchCreationRequest } from '@saved-search/models/saved-search-creation-request';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import * as savedSearchActions from '../actions/saved-search.actions';
import { SAVED_SEARCH_ERRORS_MAP } from '../constants/saved-search-errors.constants';
import * as searchCriteriaActions from '@search/store/actions/search-criteria.actions';
import { SearchStoreService } from '@search/store/services/search-store.service';
import { SavedSearchStoreService } from '../services/saved-search-store.service';
import { SavedSearchApiService } from '../services/saved-search-api.service';
import { SimpleDialogService } from '@core-utils/simple-dialog/services/simple-dialog.service';
import { RouterStoreService } from '@core-layout/app/store/services/router-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { AddEditSavedSearchDialogData } from '@saved-search/models/add-edit-saved-search-dialog-data';
import { AddEditSavedSearchDialogComponent } from '@saved-search/components/add-edit-saved-search-dialog/add-edit-saved-search-dialog.component';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import { SavedSearchData } from '@saved-search/models/saved-search-data';
import * as googleAnalyticsActions from '@core-layout/app/store/actions/google-analytics.actions';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import * as newMatchesActions from '@listings/store/actions/new-matches.actions';
import * as listingsActions from '@listings/store/actions/listings.actions';

@Injectable()
export class SavedSearchEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly savedSearchApiService: SavedSearchApiService,
        public readonly customerActivityService: CustomerActivityService,
        private readonly userStoreService: UserStoreService,
        private readonly toaster: ToastService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly searchStoreService: SearchStoreService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly simpleDialogService: SimpleDialogService,
        private readonly routerStoreService: RouterStoreService,
        private readonly routeService: RouteService,
        private readonly matDialog: MatDialog,
        private readonly settingsStoreService: SettingsStoreService,
    ) { }

    public readonly loadSavedSearches$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.loadSavedSearches),
        switchMap(() => this.settingsStoreService.getSettings().pipe(take(1))),
        filter(settings => settings.permissionSettings.canSearchForListings),
        switchMap(() => this.savedSearchApiService.loadSavedSearches()),
    ));

    public readonly loadSavedSearchesFolders$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.loadSavedSearches),
        switchMap(() => this.settingsStoreService.getSettings().pipe(take(1))),
        filter(settings => settings.permissionSettings.canSearchForListings),
        switchMap(() => [foldersActions.loadFolders({ shouldSetLoading: false }), foldersActions.loadFolderSavedSearches()]),
    ));

    public readonly createSavedSearchRequested$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.createSavedSearchRequested),
        concatLatestFrom(() => [this.searchStoreService.searchOptions$, this.userStoreService.getUser()]),
        map(([{ savedSearchModification }, searchOptions, user]) => {
            const savedSearch: SavedSearchCreationRequest = {
                id: savedSearchModification.id,
                name: savedSearchModification.name,
                criteria: searchOptions,
                searchNewMatches: savedSearchModification.searchNewMatches,
                customerId: user.customerId,
                folderId: savedSearchModification.folderId
            };

            return savedSearchActions.createSavedSearch({ savedSearch });
        })
    ));

    public readonly createSavedSearch$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.createSavedSearch),
        switchMap(({ savedSearch }) => this.savedSearchApiService.createSavedSearch(savedSearch))
    ));

    public readonly createSavedSearchSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.createSavedSearchSuccess),
        concatLatestFrom(() => this.searchStoreService.searchOptions$),
        switchMap(([{ savedSearchId, savedSearchName, folderId }, searchOptions]) => [
            foldersActions.updateFolderSavedSearch({ currentFolderId: folderId, savedSearchId, savedSearchName }),
            savedSearchActions.addCustomerSavedSearchActivity({ savedSearchId, isNew: true, categoryId: searchOptions.categoryId })
        ])
    ));

    public readonly notifySavedSearchCreated$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.createSavedSearchSuccess),
        mergeMap(({ savedSearchId, savedSearchName }) => {
            const details = {
                id: savedSearchId,
                name: savedSearchName
            };

            return this.savedSearchApiService.notifySavedSearchCreated(details);
        })
    ), { dispatch: false });

    public readonly updateSavedSearchRequested$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.updateSavedSearchRequested),
        concatLatestFrom(({ savedSearchModification }) => [
            this.savedSearchStoreService.getSavedSearch(savedSearchModification.id),
            this.searchStoreService.searchOptions$,
            this.foldersStoreReadService.getFolderIdAttachedToSavedSearch(savedSearchModification.id),
            this.foldersStoreReadService.getNewMatchesIdsNotInFolder(savedSearchModification.id, savedSearchModification.folderId)
        ]),
        switchMap(([{ savedSearchModification }, savedSearch, searchOptions, folderId, newMatchesIds]) => {
            const { updateCriteria } = savedSearchModification;

            const criteria = updateCriteria ? searchOptions : savedSearch.criteria;
            const previousSavedSearch: SavedSearchCreationRequest = { ...savedSearch, folderId };
            const currentSavedSearch: SavedSearchCreationRequest = { ...savedSearch, ...savedSearchModification, criteria };

            return [
                savedSearchActions.updateSavedSearch({ previousSavedSearch, currentSavedSearch, newMatchesIds, updateCriteria }),
                foldersActions.updateFolderSavedSearch({
                    currentFolderId: savedSearchModification.folderId,
                    savedSearchId: savedSearchModification.id,
                    savedSearchName: savedSearchModification.name
                })
            ];
        })
    ));

    public readonly updateSavedSearch$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.updateSavedSearch),
        switchMap(({ previousSavedSearch, currentSavedSearch, newMatchesIds }) =>
            this.savedSearchApiService.updateSavedSearch(previousSavedSearch, currentSavedSearch, newMatchesIds))
    ));

    public readonly updateSavedSearchSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.updateSavedSearchSuccess),
        concatLatestFrom(({ savedSearch }) => [
            this.savedSearchStoreService.getSavedSearch(savedSearch.id),
            this.listingsStoreService.getsSavedSearchNewMatches(savedSearch.id),
            this.foldersStoreReadService.getFolderIdAttachedToSavedSearch(savedSearch.id),
        ]),
        switchMap(([{ savedSearch, includePreviousMatches }, updatedSearch, savedSearchNewMatches, attachedFolderId]) => {

            if (savedSearch.searchNewMatches === updatedSearch.searchNewMatches && !includePreviousMatches && savedSearch.folderId === attachedFolderId) {
                return EMPTY;
            }

            const actions: Action[] = [];

            if (!updatedSearch.searchNewMatches) {
                const newMatchIds = savedSearchNewMatches.map(x => x.id);

                actions.push(
                    foldersActions.updateFolderMappings({ newMatchIds }),
                    newMatchesActions.removeNewMatches({ hashCodes: [], newMatchIds: new Set(newMatchIds) })
                );
            } else {
                actions.push(
                    foldersActions.loadListingFolderIdsMappings(),
                    foldersActions.loadFolderNewMatches(),
                    newMatchesActions.loadListingsNewMatchesRequested({ hashCodes: [], shouldSetLoading: true, shouldSetLoaded: false, isForce: true }),
                    listingsActions.loadCustomerListings({ shouldSetLoading: true })
                );
            }

            return actions;
        })
    ));

    public readonly updateSavedSearchFailed$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.updateSavedSearchFailed),
        switchMap(({ savedSearch }) => {

            return [
                foldersActions.updateFolderSavedSearch({
                    currentFolderId: savedSearch.folderId,
                    savedSearchId: savedSearch.id,
                    savedSearchName: savedSearch.name
                })
            ];
        })
    ));

    public readonly deleteSavedSearch$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.deleteSavedSearch),
        concatLatestFrom(({ savedSearch }) => [
            this.savedSearchStoreService.activeSavedSearchId$,
            this.foldersStoreReadService.getFolderIdAttachedToSavedSearch(savedSearch.id)
        ]),
        switchMap(([{ savedSearch }, activeSavedSearchId, folderId]) => {
            const shouldRemoveActiveSavedSearchId = savedSearch.id === activeSavedSearchId;

            return this.savedSearchApiService.deleteSavedSearch(savedSearch, folderId, shouldRemoveActiveSavedSearchId);
        })
    ));

    public readonly deleteSavedSearchSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.deleteSavedSearchSuccess),
        concatLatestFrom(({ savedSearch }) => [
            this.listingsStoreService.getsSavedSearchNewMatches(savedSearch.id),
            this.savedSearchStoreService.activeSavedSearchId$
        ]),
        switchMap(([{ savedSearch }, savedSearchNewMatches, activeSavedSearchId]) => {
            const newMatchIds = savedSearchNewMatches.map(x => x.id);

            const actions: Action[] = [
                foldersActions.deleteSavedSearchFolders({ savedSearchId: savedSearch.id }),
                foldersActions.updateFolderMappings({ newMatchIds }),
                newMatchesActions.removeNewMatches({ hashCodes: [], newMatchIds: new Set(newMatchIds) })
            ];

            if (savedSearch.id === activeSavedSearchId) {
                actions.push(savedSearchActions.activateSavedSearch({ activeSavedSearchId: null, shouldSetLoading: false }));
            }

            return actions;
        })
    ));

    public readonly deleteSavedSearchFailed$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.deleteSavedSearchFailed),
        concatLatestFrom(({ savedSearch }) => this.foldersStoreReadService.getFolderIdAttachedToSavedSearch(savedSearch.id)),
        switchMap(([{ savedSearch, removeActiveSavedSearchId }, folderId]) => [
            foldersActions.updateFolderSavedSearch({
                currentFolderId: folderId,
                savedSearchId: savedSearch.id,
                savedSearchName: savedSearch.name
            }),
            ...(removeActiveSavedSearchId ? [savedSearchActions.activateSavedSearch({ activeSavedSearchId: savedSearch.id, shouldSetLoading: false })] : [])
        ])
    ));

    public readonly showSavedSearchError$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                savedSearchActions.loadSavedSearchesFailed,
                savedSearchActions.createSavedSearchFailed,
                savedSearchActions.updateSavedSearchFailed,
                savedSearchActions.deleteSavedSearchFailed),
            tap(({ error }) => {
                if (SAVED_SEARCH_ERRORS_MAP.has(error.errorKey)) {
                    this.toaster.showClientError(SAVED_SEARCH_ERRORS_MAP.get(error.errorKey));
                }
            })
        ),
        { dispatch: false }
    );

    public readonly activateSavedSearch$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.activateSavedSearch),
        concatLatestFrom(({ activeSavedSearchId }) => this.savedSearchStoreService.getSavedSearch(activeSavedSearchId)),
        switchMap(([{ shouldSetLoading }, savedSearchToActivate]) => {
            const actions: Action[] = [savedSearchActions.setActiveSavedSearchId({ activeSavedSearchId: savedSearchToActivate?.id ?? null })];

            if (savedSearchToActivate != null) {
                actions.push(
                    searchCriteriaActions.setSearchOptions({ searchOptions: savedSearchToActivate.criteria }),
                    searchCriteriaActions.search({ searchOptions: savedSearchToActivate.criteria, shouldSetLoading }),
                );
            }

            return actions;
        }))
    );

    public readonly addCustomerSavedSearchActivity$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(savedSearchActions.addCustomerSavedSearchActivity),
                concatLatestFrom(({ savedSearchId }) => [
                    this.listingsStoreService.getMarketListings(),
                    this.savedSearchStoreService.getSavedSearch(savedSearchId),
                    this.userStoreService.getUser()
                ]),
                switchMap(([{ savedSearchId, isNew, categoryId, }, onMarketListings, savedSearch, user]) => {
                    if (savedSearchId == null) {
                        return EMPTY;
                    }

                    const listingsReturnedNumber = onMarketListings.length;
                    const category = categoryId === ListingCategories.Sales ? ListingCategoryTexts.Sales : ListingCategoryTexts.Rental;

                    return this.customerActivityService.addSavedSearchActivity(
                        savedSearchId,
                        listingsReturnedNumber,
                        isNew,
                        category,
                        savedSearch.customerId,
                        user.customerId
                    );
                })
            ),
        { dispatch: false }
    );

    public readonly showDeleteSavedSearchDialog$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.showDeleteSavedSearchDialog),
        switchMap(({ savedSearch, actionOnSuccess }) => {
            const config = { showTitle: true, showCancel: true, message: 'DIALOGS.SAVED_SEARCH_DELETION' };

            return this.simpleDialogService.showForStream(config).pipe(
                filter(Boolean),
                tap(() => actionOnSuccess()),
                map(() => savedSearchActions.deleteSavedSearch({ savedSearch }))
            );
        }),
    ));

    public readonly runSavedSearch$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.runSavedSearch),
        concatLatestFrom(() => this.routerStoreService.url$),
        map(([{ savedSearchId }, url]) => {
            const isSearchPageCurrent = url.slice(1) === RpcRoute.SearchListings;

            if (!isSearchPageCurrent) {
                this.routeService.navigate(RpcRoute.SearchListings, null, url.slice(1) as RpcRoute).catch(() => { });
            }

            return savedSearchActions.activateSavedSearch({ activeSavedSearchId: savedSearchId, shouldSetLoading: true });
        }),
    ));

    public readonly showAddEditSavedSearchDialog$ = createEffect(() => this.actions$.pipe(
        ofType(savedSearchActions.showAddEditSavedSearchDialog),
        concatLatestFrom(({ savedSearchId }) => [
            this.savedSearchStoreService.savedSearches$,
            this.savedSearchStoreService.getSavedSearch(savedSearchId),
            this.foldersStoreReadService.foldersSavedSearches$,
        ]),
        switchMap(([{ savedSearchId }, savedSearches, savedSearch, foldersSavedSearches]) => {
            const folderId = foldersSavedSearches.find(x => x.savedSearchId === savedSearchId)?.folderId;
            const data: AddEditSavedSearchDialogData = { savedSearches, savedSearch, folderId };
            const dialogConfig: MatDialogConfig = { data, autoFocus: false, restoreFocus: false, panelClass: 'saved-search-edit-modal' };

            return this.matDialog.open(AddEditSavedSearchDialogComponent, dialogConfig).afterClosed().pipe(
                filter(Boolean),
                switchMap((result: SavedSearchData) => {
                    const isUpdate = savedSearch != null;
                    const savedSearchModification = { ...result, updateCriteria: !isUpdate };

                    const actions: Action[] = [savedSearch != null
                        ? savedSearchActions.updateSavedSearchRequested({ savedSearchModification })
                        : savedSearchActions.createSavedSearchRequested({ savedSearchModification })
                    ];

                    if (isUpdate) {
                        if (folderId !== result.folderId) {
                            actions.push(googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.SavedSearchEdition_AttachFolder }));
                        }
                    } else {
                        actions.push(googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.SavedSearchCreation }));

                        if (result.folderId != null) {
                            actions.push(googleAnalyticsActions.addEvent({ name: GoogleAnalyticsEventName.SavedSearchCreation_AttachFolder }));
                        }
                    }

                    return actions;
                })
            );
        }),
    ));

    public readonly activateSavedSearchOnSearchCriteriaMatch$ = createEffect(() => this.actions$.pipe(
        ofType(searchCriteriaActions.setSearchOptions),
        concatLatestFrom(() => [this.savedSearchStoreService.savedSearches$, this.savedSearchStoreService.activeSavedSearch$]),
        switchMap(([{ searchOptions }, savedSearches, currentActiveSavedSearchId]) => {

            if (isEqual(currentActiveSavedSearchId?.criteria, searchOptions)) {
                return EMPTY;
            }

            const activeSavedSearchId = savedSearches.find(({ criteria }) => isEqual(criteria, searchOptions))?.id;

            return of(savedSearchActions.setActiveSavedSearchId({ activeSavedSearchId }));
        })
    ));
}