import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import { gql } from 'app/graphql/gql';
import { SavedSearchDetailsInput } from 'app/graphql/graphql';

import { ApiDataResult, ApiResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { SavedSearch } from '@saved-search/models/saved-search';
import { SavedSearchCreationRequest } from '@saved-search/models/saved-search-creation-request';
import * as savedSearchActions from '../actions/saved-search.actions';

@Injectable({ providedIn: 'root' })
export class SavedSearchApiService {

    constructor(
        private readonly http: ApiHttpClient,
        private readonly apollo: Apollo
    ) { }

    public loadSavedSearches(): Observable<Action> {
        return this.http.get('saved-search/all').pipe(
            map((response: ApiDataResult<SavedSearch[]>) => {
                return response.error != null
                    ? savedSearchActions.loadSavedSearchesFailed({ error: response.error })
                    : savedSearchActions.loadSavedSearchesSuccess({ savedSearches: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                const error = errorResponse.status === HttpStatusCode.Forbidden
                    ? new ApiError('Saved_Search_Load_Forbidden')
                    : errorResponse.error as ApiError;

                return of(savedSearchActions.loadSavedSearchesFailed({ error }));
            })
        );
    }

    public createSavedSearch(savedSearch: SavedSearchCreationRequest): Observable<Action> {
        return this.http.post('saved-search', {
            name: savedSearch.name,
            searchCriteria: savedSearch.criteria,
            searchNewMatches: savedSearch.searchNewMatches,
            folderId: savedSearch.folderId
        }).pipe(
            switchMap((response: ApiDataResult<number>) => {
                return response.error != null
                    ? [savedSearchActions.createSavedSearchFailed({ error: response.error, savedSearch })]
                    : [
                        savedSearchActions.createSavedSearchSuccess({
                            tempId: savedSearch.id,
                            savedSearchId: response.result,
                            savedSearchName: savedSearch.name,
                            folderId: savedSearch.folderId
                        }),
                        // savedSearchActions.addCustomerSavedSearchActivity({ savedSearchId: response.result, isNew: true, categoryId: savedSearch.criteria.categoryId })
                    ];
            }),
            catchError((errorResponse: HttpErrorResponse) => of(savedSearchActions.createSavedSearchFailed({
                error: errorResponse.status === HttpStatusCode.Forbidden
                    ? new ApiError('Saved_Search_Creation_Forbidden')
                    : errorResponse.error as ApiError,
                savedSearch
            })))
        );
    }

    public updateSavedSearch(
        previousSavedSearch: SavedSearchCreationRequest,
        currentSavedSearch: SavedSearchCreationRequest,
        newMatchesIds: number[],
    ): Observable<Action> {
        return this.http.put('saved-search', {
            savedSearchId: currentSavedSearch.id,
            name: currentSavedSearch.name,
            searchCriteria: currentSavedSearch.criteria,
            searchNewMatches: currentSavedSearch.searchNewMatches,
            includePreviousMatches: currentSavedSearch.includePreviousMatches,
            folderId: currentSavedSearch.folderId
        }).pipe(
            map((response: ApiResult) => {
                const updateSavedSearchPayload = {
                    savedSearch: previousSavedSearch,
                    folderId: currentSavedSearch.folderId,
                    includePreviousMatches: currentSavedSearch.includePreviousMatches,
                    newMatchesIds,
                };

                return response.error != null
                    ? savedSearchActions.updateSavedSearchFailed({ ...updateSavedSearchPayload, error: response.error })
                    : savedSearchActions.updateSavedSearchSuccess({ ...updateSavedSearchPayload });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(savedSearchActions.updateSavedSearchFailed({
                error: errorResponse.status === HttpStatusCode.Forbidden
                    ? new ApiError('Saved_Search_Update_Forbidden')
                    : errorResponse.error as ApiError,
                savedSearch: previousSavedSearch,
                folderId: currentSavedSearch.folderId,
                includePreviousMatches: currentSavedSearch.includePreviousMatches,
                newMatchesIds,
            })))
        );
    }

    public deleteSavedSearch(savedSearch: SavedSearch, folderId: number | null, removeActiveSavedSearchId: boolean): Observable<Action> {
        return this.http.post('saved-search/delete', { savedSearchId: savedSearch.id, folderId })
            .pipe(
                map((response: ApiResult) => {
                    return response.error != null
                        ? savedSearchActions.deleteSavedSearchFailed({ savedSearch, removeActiveSavedSearchId, error: response.error })
                        : savedSearchActions.deleteSavedSearchSuccess({ savedSearch });
                }),
                catchError((errorResponse: HttpErrorResponse) => of(savedSearchActions.deleteSavedSearchFailed({
                    savedSearch,
                    removeActiveSavedSearchId,
                    error: errorResponse.status === HttpStatusCode.Forbidden
                        ? new ApiError('Saved_Search_Deletion_Forbidden')
                        : errorResponse.error as ApiError
                })))
            );
    }

    public notifySavedSearchCreated(details: SavedSearchDetailsInput) {
        return this.apollo.mutate({
            mutation: gql(`
                    mutation NotifySearchSaved($details: SavedSearchDetailsInput!) {
                        applicationEvents {
                            searchSaved(details: $details)
                        }
                    }
                `),
            variables: {
                details
            }
        });
    }
}